<template>
  <div
    :class="{
      '--has-slot': !!$slots.default
    }"
    class="ctk-input-address-predictions-empty tw-absolute tw-left-0 tw-bg-white tw-overflow-auto tw-text-center tw-py-2 tw-px-0 tw-w-full"
  >
    <slot
      name="no-result"
    >
      <div
        v-text="$t('app.values.no_result')"
      />
    </slot>

    <slot />
  </div>
</template>

<script>
  /**
   * @module component - CtkInputAddressPredictionsEmpty
   */
  export default {
    name: 'CtkInputAddressPredictionsEmpty'
  }
</script>

<style lang="scss" scoped>

  .ctk-input-address-predictions-empty {
    max-height: 200px;
    z-index: 9;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);

    &.--has-slot {
      border-left: 5px solid $info;
    }
  }

</style>
