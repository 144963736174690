<template functional>
  <ul
    class="tw-m-0 tw-p-0 tw-px-2"
  >
    <li
      v-for="item in props.items"
      :key="item.key"
      class="tw-flex tw-items-center tw-mb-2 tw-text-left"
      :class="{ 'tw-text-blue-500': item.checked, 'tw-text-secondary-text' : !item.checked, 'tw-text-orange-500': item.warning }"
      data-test="item"
    >
      <ui-material-icon
        v-if="item.warning"
        class="tw-text-sm tw-mr-2"
        name="warning"
        data-test="icon"
      />
      <ui-material-icon
        v-else
        class="tw-text-sm tw-mr-2"
        :name="item.checked ? 'check' : 'close'"
        data-test="icon"
      />
      <span
        v-text="item.text"
        data-test="content"
      />
    </li>
  </ul>
</template>

<script>
  /**
   * @module component - CheckedItemsList
   * @param {Array<{ checked: boolean, key: string, text: string, warning: boolean }>} items
   */
  export default {
    name: 'CheckedItemsList',
    props: {
      items: {
        type: Array,
        required: true
      }
    }
  }
</script>
