<template>
  <checked-items-list
    class="offer-reassurance-list"
    :items="items"
  />
</template>

<script>
  import CheckedItemsList from '@/components/CheckedItemsList/index.vue'

  const OFFERS_REASSURANCE_ITEMS = {
    chronotruck: [
      { key: 'app.paragraphs.offers.chronotruck.reassurances.carrier_lookup', checked: true },
      { key: 'app.paragraphs.offers.chronotruck.reassurances.indicative_price', checked: true },
      { key: 'app.paragraphs.offers.chronotruck.reassurances.direct_routing', checked: true },
      { key: 'app.paragraphs.offers.chronotruck.reassurances.all_vehicles', checked: true },
      { key: 'app.paragraphs.offers.network.reassurances.meeting_option', checked: true },
      { key: 'app.paragraphs.offers.network.reassurances.flexible_hours', checked: true }
    ],
    custom: [
      { key: 'app.paragraphs.offers.chronotruck.reassurances.carrier_lookup', checked: true },
      { key: 'app.paragraphs.offers.chronotruck.reassurances.direct_routing', checked: true },
      { key: 'app.paragraphs.offers.chronotruck.reassurances.all_vehicles', checked: true },
      { key: 'app.paragraphs.offers.network.reassurances.meeting_option', checked: true },
      { key: 'app.paragraphs.offers.network.reassurances.flexible_hours', checked: true }
    ],
    pallet_network: [
      { key: 'app.paragraphs.offers.network.reassurances.immediate_assignment', checked: true },
      { key: 'app.paragraphs.offers.network.reassurances.guaranteed_price', checked: true },
      { key: 'app.paragraphs.offers.network.reassurances.dock', checked: true },
      { key: 'app.paragraphs.offers.network.reassurances.heavy_truck_only', checked: true },
      { key: 'app.paragraphs.offers.network.reassurances.meeting_option', checked: false },
      { key: 'app.paragraphs.offers.network.reassurances.flexible_hours', checked: false }
    ]
  }

  /**
   * @module component - OfferReassuranceList
   * @param {string} offerType
   */
  export default {
    name: 'OfferReassuranceList',
    components: {
      CheckedItemsList
    },
    props: {
      offerType: {
        type: String,
        required: true
      }
    },
    computed: {
      /**
       * @function items
       * @returns {Array<string>}
       */
      items () {
        // @ts-ignore
        return OFFERS_REASSURANCE_ITEMS[this.offerType].map(item => ({
          ...item,
          text: this.$t(item.key)
        }))
      }
    }
  }
</script>
