<template>
  <div
    class="ctk-input-address"
    v-click-outside="onBlur"
  >
    <div class="tw-flex">
      <div class="ctk-input-address__field-wrapper tw-flex-1">
        <ctk-input-text
          id="address"
          ref="address"
          v-model="addressValue"
          :label="label"
          :error="!!error"
          :hint="error"
          :disabled="$attrs.disabled"
          :loader="$wait.is(`fetching predictions for ${$attrs.id}`)"
          :has-prefix="true"
          class="tw-flex-1"
          type="text"
          name="address"
          required
          clearable
          autocomplete="off"
          @input="lazyFetchAddresses"
          @focus="onFocus"
          @clear="clear"
          @keyup="checkShow"
        >
          <template #prefix>
            <ui-ctk-icon
              :class="[
                direction === 'pickup' ? 'tw-text-pickup' : 'tw-text-delivery'
              ]"
              name="marker"
            />
          </template>
        </ctk-input-text>
        <transition name="slide">
          <ctk-input-address-predictions
            v-if="(isFocus && predictions.length > 0) || (isFocus && isDirty)"
            ref="predictions"
            :id="$attrs.id"
            :direction="direction"
            :predictions="predictions"
            :prediction="lastPrediction && lastPrediction.id"
            :has-help="isHelpVisible"
            :has-error="hasError"
            class="tw-z-50"
            :class="{
              'is-disabled': $attrs.disabled
            }"
            @select="selectPrediction"
            @re-focus="refocus"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { debounce } from 'underscore'

  import { isTesting } from '@/../utils/env'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import { getPredictionsFromAddresses } from '@/providers/AddressProvider/ChronotruckAddressProvider'
  import { KEYCODES } from '@/composables/constants'
  import useCountryLists from '@/composables/useCountryLists'

  import CtkInputAddressPredictions from './_subs/CtkInputAddressPredictions/index.vue'

  import { directive } from 'v-click-outside'
  import GoogleAddressProvider from '@/providers/AddressProvider/GoogleAddressProvider'

  /**
   * @module component - CtkInputAddress
   * @param {string} label - Label shown in the address field
   * @param {string} direction - Either "pickup" or "delivery"
   * @param {boolean} [hasHelp=true] - Show the help section in the empty predictions section
   * @param {object} provider - Address provider to be used
   * @param {Array<any>} [fetchedAddresses=[]] - A list of addresses that were pre-fetched
   * from the back-end
   * @param {string} [error=null]
   * @param {Array<string>} [disabledCountries=[]] - A list of ISO-2 country codes to disable
   */
  export default defineComponent({
    name: 'CtkInputAddress',
    components: {
      CtkInputText,
      CtkInputAddressPredictions
    },
    directives: {
      clickOutside: directive
    },
    props: {
      label: {
        type: String,
        required: true
      },
      direction: {
        type: String,
        required: true
      },
      hasHelp: {
        type: Boolean,
        default: false
      },
      provider: {
        type: Object,
        required: true
      },
      fetchedAddresses: {
        type: Array,
        default: () => ([])
      },
      disabledCountries: {
        type: Array,
        default: () => ([])
      },
      value: {
        type: [String, Number, Object],
        default: null
      },
      error: {
        type: String,
        default: null
      },
      isDisabled: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: addressValue } = useModelGetterSetter(props, 'value')

      const { getAvailableCountries } = useCountryLists()
      const availableCountries = getAvailableCountries()
      const countries = availableCountries.map(c => c['iso-2'])

      return {
        addressValue,
        countries
      }
    },
    data () {
      return {
        predictions: [],
        isDirty: false,
        isFocus: false,
        lastPrediction: null,
        loadTimeout: null,
        hasError: false,
        predictionTries: 0
      }
    },
    computed: {
      /**
       * Returns true if the help section should be visible
       * @function isHelpVisible
       * @returns {boolean}
       */
      isHelpVisible () {
        return this.hasHelp && this.predictionTries > 2
      },
      /**
       * Returns the fetched addresses, mapped as predictions
       * @function getFetchedPredictions
       * @returns {Array} predictions
       */
      getFetchedPredictions () {
        return getPredictionsFromAddresses(this.fetchedAddresses)
      }
    },
    mounted () {
      /**
       * Do not inject the Google script if we are in the testing env.
       */
      if (!window.google && !isTesting) {
        new GoogleAddressProvider().inject()
      }

      this.resetPredictions()
    },
    methods: {
      countryUpdated () {
        this.resetPredictions()
      },
      async clear () {
        this.isDirty = false
        this.$emit('clear')
        await this.$nextTick()
        this.resetPredictions()
      },
      refocus () {
        this.$refs.address.focusInput()
      },
      onFocus () {
        this.isFocus = true
      },
      onBlur () {
        this.isFocus = false
      },
      /**
       * @function checkShow
       * @param {KeyboardEvent} e
       */
      checkShow (e) {
        const key = e.which || e.keyCode
        switch (key) {
        case KEYCODES.UP:
        case KEYCODES.DOWN:
          e.preventDefault()
          if (this.$refs.predictions) {
            this.$refs.predictions.focus()
          }
          break
        }
      },
      getFilteredPredictions (predictions) {
        return predictions
          .filter(({ types, source }) => {
            const isPredictionFromDisabledCountry = types.includes('saved') &&
              source && source.address &&
              this.disabledCountries.includes(source.address.country)

            return !isPredictionFromDisabledCountry
          })
      },
      resetPredictions () {
        const predictions = this.getFetchedPredictions
        this.predictions = this.getFilteredPredictions(predictions)

        this.lastPrediction = null
      },
      /**
       * @function selectPrediction
       * @param {string} predictionId
       */
      async selectPrediction (predictionId) {
        // @ts-ignore
        const prediction = this.predictions
          .map((/** @type {any} */ prediction) => ({
            ...prediction,
            isEstablishment: prediction.types.includes('establishment'),
            isSaved: prediction.types.includes('saved')
          }))
          .find((/** @type {any} */ value) => value.id === predictionId)

        this.onBlur()

        try {
          const res = await this.fetchPlaceComponents(prediction)
          this.addressValue = prediction.description
          const autocomplete = {
            address: res,
            companyName: prediction.isEstablishment || prediction.isSaved
              ? prediction.name
              : null
          }

          /**
           * Chronotruck provider includes a source object with the address
           * informations.
           */
          if (prediction.source) autocomplete.source = prediction.source
          this.lastPrediction = prediction

          this.$emit('autocomplete', autocomplete)
        } catch (e) {
          console.error('Error occured while fetching place', e)
        }
      },
      lazyFetchAddresses: debounce(
        function (value) {
          this.fetchAddresses(value)
        },
        200
      ),
      /**
       * Request the address predictions whenever the user types in the keyboard
       * @function fetchAddresses
       */
      fetchAddresses (value) {
        this.isDirty = true
        this.$emit('reset')
        this.onFocus()

        if (value !== null && value.length === 0) {
          this.resetPredictions()
          this.predictionTries += 1
        }

        if (!value) {
          return false
        }

        this.$wait.start(`fetching predictions for ${this.$attrs.id}`)
        if (this.loadTimeout) clearTimeout(this.loadTimeout)

        this.provider.setCountries(this.countries)

        this.provider.getPredictions(value, this.direction)
          .then(predictions => {
            this.predictions = this.getFilteredPredictions(predictions)
            if (this.predictions.length === 0) {
              this.predictionTries += 1
            }
          })
          .finally(() => {
            this.loadTimeout = setTimeout(() => {
              this.$wait.end(`fetching predictions for ${this.$attrs.id}`)
            }, 500)
          })
      },
      async fetchPlaceComponents (prediction) {
        return this.provider.getItem(prediction, this.direction, this.predictions)
      }
    }
  })
</script>

<style lang="scss">

  .ctk-input-address {
    position: relative;

    .ctk-input-address-countries {
      width: 60px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    &__field-wrapper {
      position: relative;
    }

    .ctk-input-address__field-wrapper,
    .ctk-input-address-countries {
      height: 42px;
    }

    .is-disabled {
      background-color: rgba(0, 0, 0, 0.03);
      opacity: 0.7;
      filter: grayscale(1);
      cursor: not-allowed;
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    opacity: 1;
    transition: all 200ms ease-in-out;
    transform: translateY(0);
    z-index: 100;
  }

  .slide-enter,
  .slide-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }

  .ctk-input-address .ctk-input-select__wrapper {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

</style>
