<template>
  <ctk-dialog
    v-model="dialogValue"
    id="compare-dialog"
    :persistent="false"
    modal-class="new-shipment-quotation-learn-more-dialog"
    max-width="875px"
    hide-header
    hide-footer
  >
    <div class="tw-p-4 md:tw-p-10">
      <div class="new-shipment-quotation-learn-more-dialog__header tw-flex tw-flex-col md:tw-flex-row md:tw-items-end tw-mb-4">
        <img
          :src="icon"
          alt=""
          width="65"
          height="53"
          class="tw-mr-4"
          data-test="illu"
        >

        <div class="tw-flex tw-flex-col">
          <h1
            class="tw-italic tw-mb-0 tw-text-base"
            data-test="title"
          >
            <span
              v-text="offers[priceType]"
              class="tw-font-normal tw-mr-2"
              data-test="title-sub"
            />
            <span
              v-if="priceType === 'pallet_network'"
              v-text="`(${$t('new-shipment.titles.pallet_network_meaning')})`"
              class="tw-text-xs"
              data-test="title-meaning"
            />
          </h1>
          <h2
            v-text="$t('new-shipment.titles.steps')"
            class="new-shipment-quotation-learn-more-dialog__subtitle tw-font-medium tw-text-sm tw-leading-normal tw-mb-0"
            data-test="subtitle"
          />
        </div>
      </div>

      <ctk-dialog-close-button
        class="tw-top-0 tw-right-0"
        data-test="close"
        @click.native="dialogValue = false"
      />

      <div
        class="tw-mb-5 tw-flex tw-flex-col md:tw-flex-row md:tw-flex-wrap"
      >
        <div
          class="new-shipment-quotation-learn-more-dialog__steps"
          data-test="steps"
        >
          <div
            class="new-shipment-quotation-learn-more-dialog__steps__illu tw-hidden md:tw-flex tw-relative"
          >
            <template
              v-if="priceType === 'pallet_network'"
            >
              <div class="new-shipment-quotation-learn-more-dialog__steps__illu__search tw-absolute tw-w-1/2">
                <img
                  src="@/assets/img/illustrations/learn-more-illu-search.svg"
                  width="348"
                  height="88"
                  alt=""
                >
              </div>
              <div class="new-shipment-quotation-learn-more-dialog__steps__illu__network-storage tw-absolute tw-flex tw-flex-col tw-text-center">
                <div
                  v-text="$t('new-shipment.labels.warehouse')"
                />
                <img
                  src="@/assets/img/illustrations/learn-more-illu-storage.svg"
                  width="134"
                  height="61"
                  alt=""
                  class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-mx-auto"
                >
              </div>
              <img
                src="@/assets/img/illustrations/learn-more-illu-delivered.svg"
                width="139"
                height="84"
                alt=""
                class="new-shipment-quotation-learn-more-dialog__steps__illu__delivered tw-absolute"
              >
              <div
                class="new-shipment-quotation-learn-more-dialog__steps__illu__network-pickup-truck tw-absolute tw-flex tw-flex-col tw-text-center"
              >
                <div
                  v-text="$t('new-shipment.labels.first_vehicle')"
                />
                <img
                  src="@/assets/img/illustrations/learn-more-illu-pickup-truck.svg"
                  width="67"
                  height="21"
                  alt=""
                  class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-mx-auto"
                >
              </div>
              <div class="new-shipment-quotation-learn-more-dialog__steps__illu__network-delivery-truck tw-absolute tw-flex tw-flex-col tw-text-center">
                <div
                  v-text="$t('new-shipment.labels.second_vehicle')"
                />
                <img
                  src="@/assets/img/illustrations/learn-more-illu-delivery-truck.svg"
                  width="67"
                  height="21"
                  alt=""
                  class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-mx-auto"
                >
              </div>
            </template>

            <template
              v-else
            >
              <div class="new-shipment-quotation-learn-more-dialog__steps__illu__search tw-absolute tw-w-1/2">
                <img
                  src="@/assets/img/illustrations/learn-more-illu-search.svg"
                  width="348"
                  height="88"
                  alt=""
                >
              </div>
              <img
                src="@/assets/img/illustrations/learn-more-illu-storage.svg"
                width="134"
                height="61"
                alt=""
                class="new-shipment-quotation-learn-more-dialog__steps__illu__storage tw-absolute"
              >
              <img
                src="@/assets/img/illustrations/learn-more-illu-delivered.svg"
                width="139"
                height="84"
                alt=""
                class="new-shipment-quotation-learn-more-dialog__steps__illu__delivered tw-absolute"
              >
              <img
                src="@/assets/img/illustrations/learn-more-illu-pickup-truck.svg"
                width="67"
                height="21"
                alt=""
                class="new-shipment-quotation-learn-more-dialog__steps__illu__pickup-truck tw-absolute"
              >
            </template>
          </div>
          <div class="new-shipment-quotation-learn-more-dialog__steps__items tw-flex tw-flex-col md:tw-flex-row">
            <template
              v-if="priceType === 'pallet_network'"
            >
              <new-shipment-quotation-learn-more-dialog-step-item
                v-for="(step, k) in steps"
                :key="k"
                :data-test="k"
                :title="$t(step.title, {
                  offer_name: offers.pallet_network
                })"
                :content="$t(step.content, {
                  offer_name: offers.pallet_network
                })"
                :secondary="step.secondary"
                :dock="k === 'dock'"
                class="md:tw-w-1/5 tw-pl-5 md:tw-pl-0 tw-pb-4"
              />
            </template>
            <template
              v-else
            >
              <div
                class="tw-w-full md:tw-hidden"
              >
                <new-shipment-quotation-learn-more-dialog-step-item
                  v-for="(step, k) in steps"
                  :key="k"
                  :data-test="k"
                  :title="$t(step.title)"
                  :content="$t(step.content)"
                  :secondary="step.secondary"
                  class="md:tw-w-1/5 tw-pl-5 md:tw-pl-0 tw-pb-4"
                />
              </div>
              <div class="tw-w-full tw-hidden md:tw-flex">
                <new-shipment-quotation-learn-more-dialog-step-item
                  :data-test="'confirmed'"
                  :title="$t(steps.confirmed.title)"
                  :content="$t(steps.confirmed.content)"
                  class="md:tw-w-1/5 tw-pl-5 md:tw-pl-0 tw-pb-4"
                />
                <new-shipment-quotation-learn-more-dialog-step-item
                  :data-test="'searching'"
                  :title="$t(steps.searching.title)"
                  :content="$t(steps.searching.content)"
                  class="md:tw-w-1/5 tw-pl-5 md:tw-pl-0 tw-pb-4"
                />
                <new-shipment-quotation-learn-more-dialog-step-item
                  :data-test="'booked'"
                  :title="$t(steps.booked.title)"
                  :content="$t(steps.booked.content)"
                  class="md:tw-w-1/5 tw-pl-5 md:tw-pl-0 tw-pb-4"
                />
                <div
                  class="new-shipment-quotation-learn-more-dialog__steps__items__pickup md:tw-w-2/5 tw-pl-5 md:tw-pl-0 tw-pb-4"
                >
                  <h3 class="new-shipment-quotation-learn-more-dialog__steps__items__pickup__title tw-flex tw-justify-between tw-relative tw-text-sm tw-text-blue-500 tw-font-medium tw-mb-0">
                    <span
                      v-text="$t('new-shipment.titles.learn_more.pickup')"
                    />
                    <span
                      v-text="$t('new-shipment.titles.learn_more.delivery')"
                    />
                  </h3>
                  <p
                    v-text="$t(steps.pickup_delivery.content)"
                    class="new-shipment-quotation-learn-more-dialog__steps__items__pickup__content fs-13 tw-leading-tight"
                  />
                </div>
              </div>
            </template>
          </div>

          <div
            v-if="priceType === 'chronotruck'"
            class="tw-w-full tw-hidden md:tw-flex"
          >
            <div class="tw-w-2/5" />
            <div class="new-shipment-quotation-learn-more-dialog__steps__items__proposals tw-w-3/5">
              <h3
                class="new-shipment-quotation-learn-more-dialog__steps__items__proposals__title tw-flex tw-justify-between tw-relative tw-text-sm tw-font-medium tw-mb-0"
                v-text="$t(steps.proposals.title)"
              />
              <p
                v-text="$t(steps.proposals.content)"
                class="new-shipment-quotation-learn-more-dialog__steps__items__proposals__content fs-13 tw-leading-tight tw-mb-0"
              />
            </div>
          </div>

          <template
            v-if="priceType === 'pallet_network'"
          >
            <div class="tw-hidden md:tw-flex">
              <div class="tw-w-2/5" />
              <div class="tw-w-3/5">
                <div
                  class="new-shipment-quotation-learn-more-dialog__steps__timelimit tw-text-blue-500 tw-font-medium tw-text-xs tw-relative tw-text-center"
                >
                  <span
                    v-text="$t('new-shipment.paragraphs.learn_more.timelimit_fr')"
                    class="tw-relative tw-bg-white tw-px-4"
                  />
                  <span
                    v-text="$t('new-shipment.paragraphs.learn_more.timelimit_de_be')"
                    class="tw-relative tw-bg-white tw-px-4"
                  />
                </div>
                <new-shipment-quotation-learn-more-dialog-accessibility
                  class="new-shipment-quotation-learn-more-dialog__steps__accessibility tw-relative tw-mb-4"
                />
              </div>
            </div>
          </template>
        </div>

        <new-shipment-quotation-learn-more-dialog-accessibility
          v-if="priceType === 'pallet_network'"
          class="md:tw-hidden tw-mb-4"
          data-test="accessibility"
        />

        <new-shipment-quotation-learn-more-dialog-guarantees
          :price-type="priceType"
          data-test="guarantees"
        />

        <p
          v-if="priceType === 'pallet_network'"
          v-text="$t('new-shipment.paragraphs.learn_more.fragile_load')"
          class="new-shipment-quotation-learn-more-dialog__caption tw-text-xs tw-italic tw-mt-4"
          data-test="fragile-load"
        />
      </div>
    </div>
  </ctk-dialog>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import { offers } from '@/../config/chronoinfos'
  import NewShipmentQuotationLearnMoreDialogStepItem from './_subs/NewShipmentQuotationLearnMoreDialogStepItem/index.vue'
  import NewShipmentQuotationLearnMoreDialogGuarantees from './_subs/NewShipmentQuotationLearnMoreDialogGuarantees/index.vue'
  import NewShipmentQuotationLearnMoreDialogAccessibility from './_subs/NewShipmentQuotationLearnMoreDialogAccessibility/index.vue'

  /**
   * @module component - NewShipmentQuotationLearnMoreDialog
   */
  export default defineComponent({
    name: 'NewShipmentQuotationLearnMoreDialog',
    components: {
      CtkDialog,
      CtkDialogCloseButton,
      NewShipmentQuotationLearnMoreDialogStepItem,
      NewShipmentQuotationLearnMoreDialogGuarantees,
      NewShipmentQuotationLearnMoreDialogAccessibility
    },
    props: {
      value: {
        type: Boolean,
        default: true
      },
      priceType: {
        type: String,
        required: true
      }
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        dialogValue
      }
    },
    data () {
      return {
        offers
      }
    },
    computed: {
      icon () {
        /** @type {object} */
        const icons = {
          pallet_network: require('@/assets/img/icons/truck-network.svg'),
          chronotruck: require('@/assets/img/icons/truck-classic.svg')
        }

        return icons[this.priceType]
      },
      steps () {
        return {
          ...(this.priceType === 'pallet_network' ? {
            scheduled: {
              title: 'new-shipment.titles.learn_more.scheduled',
              content: 'new-shipment.paragraphs.learn_more.scheduled'
            },
            immediate: {
              title: 'new-shipment.titles.learn_more.immediate',
              content: 'new-shipment.paragraphs.learn_more.immediate'
            },
            pickup: {
              title: 'new-shipment.titles.learn_more.pickup',
              content: 'new-shipment.paragraphs.learn_more.pickup'
            },
            dock: {
              title: 'new-shipment.titles.learn_more.dock',
              content: 'new-shipment.paragraphs.learn_more.dock'
            },
            delivery: {
              title: 'new-shipment.titles.learn_more.delivery',
              content: 'new-shipment.paragraphs.learn_more.delivery'
            }
          } : {
            confirmed: {
              title: 'new-shipment.titles.learn_more.confirmed',
              content: 'new-shipment.paragraphs.learn_more.confirmed'
            },
            searching: {
              title: 'new-shipment.titles.learn_more.searching',
              content: 'new-shipment.paragraphs.learn_more.searching'
            },
            proposals: {
              title: 'new-shipment.titles.learn_more.proposals',
              content: 'new-shipment.paragraphs.learn_more.proposals',
              secondary: true
            },
            booked: {
              title: 'new-shipment.titles.learn_more.booked',
              content: 'new-shipment.paragraphs.learn_more.booked'
            },
            pickup_delivery: {
              title: 'new-shipment.titles.learn_more.pickup_delivery',
              content: 'new-shipment.paragraphs.learn_more.pickup_delivery'
            }
          })
        }
      }
    }
  })
</script>

<style lang="scss">
.new-shipment-quotation-learn-more-dialog .modal-container {
  width: 100%;
  margin-bottom: 1rem;
  max-height: initial;
}
.new-shipment-quotation-learn-more-dialog .modal-wrapper {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.new-shipment-quotation-learn-more-dialog__header {
  border-bottom: 1px solid $divider;
  padding-bottom: 1rem;
}
.new-shipment-quotation-learn-more-dialog__close {
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  border: none;
  background: transparent;
  appearance: none;
  z-index: 1;
}
.new-shipment-quotation-learn-more-dialog__steps__item__content, .new-shipment-quotation-learn-more-dialog__steps__items__pickup__content, .new-shipment-quotation-learn-more-dialog__steps__items__proposals__title, .new-shipment-quotation-learn-more-dialog__steps__items__proposals__content, .new-shipment-quotation-learn-more-dialog__close, .new-shipment-quotation-learn-more-dialog__subtitle, .new-shipment-quotation-learn-more-dialog__caption {
  color: $secondary-text;
}
.new-shipment-quotation-learn-more-dialog__steps__illu {
  min-height: 150px;
  background-image: url('~@/assets/img/illustrations/city_background.svg');
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.new-shipment-quotation-learn-more-dialog__steps__illu__search, .new-shipment-quotation-learn-more-dialog__steps__illu__pickup-truck, .new-shipment-quotation-learn-more-dialog__steps__illu__delivery-truck, .new-shipment-quotation-learn-more-dialog__steps__illu__network-delivery-truck, .new-shipment-quotation-learn-more-dialog__steps__illu__network-pickup-truck, .new-shipment-quotation-learn-more-dialog__steps__illu__network-storage {
  bottom: -2rem;
}
.new-shipment-quotation-learn-more-dialog__steps__illu__pickup-truck, .new-shipment-quotation-learn-more-dialog__steps__illu__network-pickup-truck {
  left: 45%;
}
.new-shipment-quotation-learn-more-dialog__steps__illu__network-delivery-truck, .new-shipment-quotation-learn-more-dialog__steps__illu__network-pickup-truck, .new-shipment-quotation-learn-more-dialog__steps__illu__network-storage {
  height: 150px;
  color: $secondary-text;
}
.new-shipment-quotation-learn-more-dialog__steps__illu__network-delivery-truck::before, .new-shipment-quotation-learn-more-dialog__steps__illu__network-pickup-truck::before, .new-shipment-quotation-learn-more-dialog__steps__illu__network-storage::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 1px;
  height: 75%;
  bottom: 1rem;
  border-left: 1px dashed #A4A4A4;
  margin: auto;
}
.new-shipment-quotation-learn-more-dialog__steps__illu__network-storage {
  height: 110px;
}
.new-shipment-quotation-learn-more-dialog__steps__illu__network-delivery-truck, .new-shipment-quotation-learn-more-dialog__steps__illu__delivery-truck {
  left: 75%;
}
.new-shipment-quotation-learn-more-dialog__steps__illu__storage, .new-shipment-quotation-learn-more-dialog__steps__illu__network-storage {
  left: 55%;
  bottom: -1.5rem;
}
.new-shipment-quotation-learn-more-dialog__steps__illu__delivered {
  right: 0;
  bottom: -1rem;
}
.new-shipment-quotation-learn-more-dialog__steps__accessibility, .new-shipment-quotation-learn-more-dialog__steps__timelimit {
  left: 16.66%;
  width: calc(100% - (16.66% * 2) - 1rem);
}
.new-shipment-quotation-learn-more-dialog__steps__timelimit span {
  display: inline-block;
  top: -0.6rem;
}
.new-shipment-quotation-learn-more-dialog__steps__timelimit span:first-child {
  margin-bottom: 0.25rem;
}
.new-shipment-quotation-learn-more-dialog__steps__timelimit span:first-child::after {
  width: 80px;
  height: 1px;
  background-color: $info;
  bottom: -2px;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 10;
}
.new-shipment-quotation-learn-more-dialog__steps__timelimit::before, .new-shipment-quotation-learn-more-dialog__steps__timelimit span:first-child::after {
  position: absolute;
  content: '';
}
.new-shipment-quotation-learn-more-dialog__steps__timelimit::before {
  width: 100%;
  height: 120px;
  top: -120px;
  left: 0;
  border: dashed 4px $info;
  border-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABFSURBVHgB7ZOxDQAgCAR5p3E2B3AIB3A2t9FYkBgDoaCg4ToSeL74R+1zk8AaDe+s7RVyAlb+P1rwndtBvECSObggvI0HpNcY4Za9qTUAAAAASUVORK5CYII=') 4 round;
  border-top: none;
}
.new-shipment-quotation-learn-more-dialog__steps__items__pickup {
  margin-top: 8rem;
}
.new-shipment-quotation-learn-more-dialog__steps__items__pickup__title::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  border: dashed 4px $info;
  border-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAzSURBVHgB7coxFQAwCAPRUDXVVgEVUQHVhhtYGWBiYcjf8nIAEc0g+37LDn1H4q66hSYHlxYGN/SOc2IAAAAASUVORK5CYII=') 4 round;
  border-top: none;
  top: -2px;
  opacity: 0.7;
}
.new-shipment-quotation-learn-more-dialog__steps__items__pickup__title span {
  position: relative;
  border-top: 2px solid $info;
  background-color: white;
}
.new-shipment-quotation-learn-more-dialog__steps__items__pickup__title span::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: $info;
  margin: auto;
  top: calc(-8rem - 7px);
  left: 0;
  right: 0;
  bottom: auto;
}
.new-shipment-quotation-learn-more-dialog__steps__items__pickup__title span::after {
  content: '';
  position: absolute;
  height: 8rem;
  top: -8rem;
  width: 1px;
  background-color: $info;
  margin: auto;
  left: 0;
  right: 0;
}
.new-shipment-quotation-learn-more-dialog__steps__items__proposals {
  position: relative;
  top: -4rem;
  margin-left: 1rem;
  margin-top: 1rem;
}
.new-shipment-quotation-learn-more-dialog__steps__items__proposals::after {
  content: '';
  position: absolute;
  left: -0.5rem;
  height: 100%;
  width: 2px;
  top: 0;
  background-color: $secondary-text;
  opacity: 0.5;
}
.new-shipment-quotation-learn-more-dialog__steps__items__proposals::before {
  content: '';
  position: absolute;
  left: calc(-16% - 1rem);
  width: 16%;
  height: 1rem;
  border: dashed 4px $secondary-text;
  border-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABBSURBVHgB7ZOxCgAgCES9fjaIPieCvrZoECIUBwcX3yboecMdauubBNYceGdtr5ATsPL/0YLv3A7iBZLMwQXhbTz4iRkFGgdQRAAAAABJRU5ErkJggg==') 4 round;
  border-top: none;
  border-right: none;
  opacity: 0.7;
  top: 2rem;
}
@media only screen and (min-width: $breakpoint-tablet) {
  .new-shipment-quotation-learn-more-dialog__steps__items {
    border-top: 2px solid $info;
    margin-top: 3rem;
  }
}
</style>
