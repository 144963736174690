<template>
  <div class="new-shipment-address-available-countries-list tw-px-5 tw-py-4 tw-z-10">
    <div
      v-if="!!title"
      class="new-shipment-address-available-countries-list__header tw-pb-1 tw-mb-4 tw-font-medium"
      v-text="title"
    />
    <ul
      data-test="list"
    >
      <li
        v-for="country in alphaSortedCountries"
        :key="country.code"
        :class="{
          disabled: country.disabled,
          'is-new': country.isNew
        }"
        class="tw-flex tw-items-center new-shipment-address-available-countries-list__item"
        data-test="item"
        @click="select(country)"
      >
        <ui-flag
          :country="country.code"
          data-test="flag"
        />
        <div
          data-test="name"
          class="new-shipment-address-available-countries-list__item__name tw-flex tw-items-center"
        >
          <span
            class="country-name"
            v-text="country.name"
          />
          <ui-material-icon
            v-if="country.isImportExport"
            class="tw-text-base tw-ml-1 tw--mb-px"
            name="info"
            data-test="icon"
            @click.native="click"
          />
        </div>
      </li>
    </ul>
    <span
      v-if="!!hasNewCountries"
      v-text="$t('new-shipment.titles.add_address.new_country_available')"
      class="new-shipment-address-available-countries-list__new-item-legend"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { sortBy } from 'underscore'

  import { i18n } from '@/locales'
  // @ts-ignore
  import useTooltipDialog from '@/composables/useTooltipDialog'
  import useCountryLists from '@/composables/useCountryLists'

  /**
   * @module component - NewShipmentAddressAvailableCountriesList
   * @param {string} title - The title of the section
   * @param {Array<string>} [disabledCountries=[]] - A list of ISO-2 country codes to disable
   */
  export default defineComponent({
    name: 'NewShipmentAddressAvailableCountriesList',
    props: {
      title: {
        type: String,
        required: false,
        default: null
      },
      disabledCountries: {
        type: Array,
        default: () => []
      }
    },
    setup () {
      const { click } = useTooltipDialog(null, i18n.t('new-shipment.paragraphs.add_address.import_export'))

      return {
        click
      }
    },
    computed: {
      /**
       * @function countries
       * @return {Array<any>}
       */
      countries () {
        const { getAvailableCountries } = useCountryLists()
        const availableCountries = getAvailableCountries()

        return availableCountries
          .map((/** @type {{ 'iso-2': string, 'disabled-for-home': boolean }} */ country) => ({
            code: country['iso-2'],
            name: this.$t(country['iso-2']),
            isBeta: !!country.beta,
            isNew: !!country.new,
            isImportExport: !!country['disabled-for-home'],
            disabled: this.disabledCountries.includes(country['iso-2'])
          }))
      },
      alphaSortedCountries () {
        const countries = [...this.countries]
        return sortBy(countries, 'name')
      },
      hasNewCountries () {
        return this.countries.some(country => !!country.isNew)
      }
    },
    methods: {
      /**
       * @function select
       * @param {{ code: string, disabled: boolean }} country
       */
      select (country) {
        if (country.disabled) return

        this.$emit('select', country.code)
      }
    }
  })
</script>

<style lang="scss" scoped>
.new-shipment-address-available-countries-list {
  background-color: $light-gray;
}
.new-shipment-address-available-countries-list ul {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 770px) {
  .new-shipment-address-available-countries-list ul {
    grid-auto-flow: column;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}
.new-shipment-address-available-countries-list ul, .new-shipment-address-available-countries-list li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.new-shipment-address-available-countries-list__header {
  --tw-border-opacity: 1;
  border-color: rgba(214, 214, 218, var(--tw-border-opacity));
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  font-size: 1rem;
}
.new-shipment-address-available-countries-list__item {
  cursor: pointer;
  margin-bottom: 0.4em !important;
}
.new-shipment-address-available-countries-list__item__name {
  line-height: 0.8;
}
.new-shipment-address-available-countries-list__item.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.new-shipment-address-available-countries-list__item.is-new .country-name {
  position: relative;
}
.new-shipment-address-available-countries-list__item.is-new .country-name::after {
  --tw-bg-opacity: 1;
  background-color: rgba(61, 170, 107, var(--tw-bg-opacity));
  height: 6px;
  position: absolute;
  top: -2px;
  right: -8px;
  width: 6px;
  content: '';
}
.new-shipment-address-available-countries-list__new-item-legend {
  display: inline-block;
  margin-left: 0.5rem;
  margin-top: 1rem;
  position: relative;
}
.new-shipment-address-available-countries-list__new-item-legend::before {
  --tw-bg-opacity: 1;
  background-color: rgba(61, 170, 107, var(--tw-bg-opacity));
  height: 6px;
  position: absolute;
  top: 5px;
  left: -8px;
  width: 6px;
  content: '';
}
</style>
