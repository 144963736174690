<template>
  <div class="ctk-input-address-predictions-help tw-flex tw-flex-col">
    <div class="tw-flex tw-items-center">
      <ui-ctk-icon
        name="phone-help"
        class="phone-help tw-text-blue-500"
      />
      <div
        class="ctk-input-address-predictions-help__content tw-flex tw-flex-col tw-text-left tw-w-full tw-pt-4 md:tw-mr-8"
      >
        <div
          class="tw-text-base tw-text-blue-500 tw-font-bold"
          v-text="$t('app.titles.need_help')"
        />
        <div
          class="tw-text-secondary tw-text-sm"
          v-text="content"
        />
      </div>
    </div>
    <ui-button
      class="tw-text-white tw-mx-auto tw-my-4 tw-px-4"
      variant="primary"
      type="button"
      data-test="help-button"
      @click="help"
    >
      {{ $t('app.buttons.need_help') }}
    </ui-button>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - CtkInputAddressPredictionsHelp
   * @param {string} direction
   * @param {string} content
   */
  export default defineComponent({
    name: 'CtkInputAddressPredictionsHelp',
    props: {
      direction: {
        type: String,
        required: true
      },
      content: {
        type: String,
        required: true
      }
    },
    methods: {
      /**
       * Called whenever the user asks for help through the CTA
       * @function help
       */
      help () {
        EventBus.$emit('dialogs:address-not-found', this.direction)
        this.$emit('click')
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-input-address-predictions-help {
    &:not(.ctk-input-address-predictions-help__not-precise) {
      .ctk-input-address-predictions-help__content {
        border-top: 1px solid $divider;
      }
    }
  }

</style>
