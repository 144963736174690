export default class AddressComponent {
  constructor (component) {
    this.component = component
  }

  /**
   * Returns the associated address component whenever it finds the type, in the types
   * list.
   * @method find
   * @param {string} type
   */
  find (type) {
    return this.component.address_components
      .find((component) => component.types.findIndex(f => f === type) !== -1)
  }

  /**
   * Returns the formated street number
   * @method streetNumber
   * @returns {string} number
   */
  get streetNumber () {
    const streetNumber = this.find('street_number')
    if (!streetNumber) return null
    return streetNumber.short_name
  }

  /**
   * Returns the formated street_name
   * @method streetName
   * @returns {string} name
   */
  get streetName () {
    const streetName = this.find('route')
    if (!streetName) {
      return null
    }

    return `${this.streetNumber ? `${this.streetNumber} ` : ''}${streetName ? streetName.long_name : ''}`
  }

  /**
   * Returns the formated city
   * @method city
   * @returns {string} city
   */
  get city () {
    // * The 'postal_town' component can be used to display the city for some countries (UK, Sweden)
    // * as per https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
    // * some others, like Slovakia, may use 'sublocality' to return the city info

    const city = this.find('locality') || this.find('administrative_area_level_3') || this.find('postal_town') || this.find('sublocality')
    return city ? city.long_name : ''
  }

  /**
   * Returns the formated postal_code
   * @method postal_code
   * @returns {string} postal_code
   */
  get postalCode () {
    /**
     * Quickfix to handle the case where only a "postal_code_prefix" is returned
     * by Google. The example occured with Poland where "Niepodległości, Gardno"
     * returned "74" as the postal code.
     *
     * Here we want to return false so the AddressComponent is not valid and triggers
     * an error later in the code.
     */
    const prefix = this.component.address_components
      .find((component) => component.types[0] === 'postal_code_prefix')
    const postalCode = this.find('postal_code')

    if (prefix && postalCode && postalCode.short_name === prefix.short_name) return false
    return postalCode ? postalCode.short_name : ''
  }

  /**
   * Returns the formated country
   * @method country
   * @returns {string} country
   */
  get country () {
    const country = this.find('country')
    return country ? country.short_name : ''
  }

  /**
   * Returns the GPS location
   * @method location
   * @returns {Object} location
   * @returns {number} location.lat
   * @returns {number} location.lng
   */
  get location () {
    const { location } = this.component.geometry
    return {
      lat: location.lat(),
      lng: location.lng()
    }
  }

  /**
   * Returns true if there are all the required components
   * to make a proper request.
   * @method hasRequiredComponents
   * @returns {boolean}
   */
  get hasRequiredComponents () {
    const location = this.location
    return !!location.lat &&
      !!location.lng &&
      !!this.city &&
      !!this.postalCode &&
      !!this.country
  }
}
